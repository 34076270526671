import axios from 'axios';

let prefix = "/product-tag"

const ProductTagApi = {
	
	getProductTagList(payload){
		return window.Vue.axiosLaravel.get( prefix + "/list", {params: payload})
	},
	createProductTag(payload){
		return window.Vue.axiosLaravel.post( prefix + "/create", payload)
	},
	archiveProductTag(payload){
		return window.Vue.axiosLaravel.delete( prefix + "/archive", {params: payload})
	},
}

export default ProductTagApi;