
let prefix = "/product"

const ProductVariationValueApi = {
	
	getProductVariationValueList(payload){
		return window.Vue.axiosLaravel.get( prefix + "/list-variation-value", {params: payload})
	},
	createProductVariationValue(payload){
		return window.Vue.axiosLaravel.post( prefix + "/create-variation-value", payload)
	},
	updateProductVariationValue(payload){
		return window.Vue.axiosLaravel.post( prefix + "/update-variation-value", payload)
	},
	deleteProductVariationValue(payload){
		return window.Vue.axiosLaravel.delete( prefix + "/delete-variation-value", {params: payload})
	},
	setProductVariationValueImage(payload, onUploadProgress = {} ){
		return window.Vue.axiosLaravel.post(prefix + "/set-variation-value-image", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearProductVariationValueImage(payload){
		return window.Vue.axiosLaravel.delete(prefix + "/clear-variation-value-image", {params: payload})
	},
}

export default ProductVariationValueApi;