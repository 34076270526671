
let prefix = "/product-variation"

const ProductVariationApi = {
	
	getProductVariationList(payload){
		return window.Vue.axiosLaravel.get( prefix + "/list", {params: payload})
	},
	getProductVariationListPagination(payload){
		return window.Vue.axiosLaravel.get( prefix + "/listpagination", {params: payload})
	},
	setProductVariationImage(payload, onUploadProgress = {} ){
		return window.Vue.axiosLaravel.post(prefix + "/set-image", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	createProductVariation(payload){
		return window.Vue.axiosLaravel.post( prefix + "/create", payload,{
			headers: { 'Content-Type': 'multipart/form-data'}
		})
	},
	updateProductVariation(payload){
		return window.Vue.axiosLaravel.post( prefix + "/update", payload,{
			headers: { 'Content-Type': 'multipart/form-data'}
		})
	},
	archiveProductVariation(payload){
		return window.Vue.axiosLaravel.delete( prefix + "/archive", {params: payload})
	},
	clearProductVariationImage(payload){
		return window.Vue.axiosLaravel.delete(prefix + "/clear-image", {params: payload})
	},
}

export default ProductVariationApi;