import VueRouter from 'vue-router'
import { setPageTitle } from '@/helpers/pageTitle'

// Layout
const BaseLayout = () => import('@/views/_layouts/BaseLayout')

// Common Pages
const Comingsoon = () => import( '@/views/_general/Comingsoon')
const Error404 = () => import( '@/views/_general/404')
const ForgotPassword = () => import('@/views/_general/ForgotPassword')
const VerifyEmail = () => import('@/views/_general/VerifyEmail')
const Login = () => import( '@/views/_general/Login')
const ResetPassword = () => import('@/views/_general/ResetPassword')

// Pages
const AuthProfile = () => import( '@/views/_auth/AuthProfile')
const BroadcastList = () => import('@/views/Broadcasts/BroadcastList')
const AdvertisementList = () => import( '@/views/Advertisements/AdvertisementList')
const AnnouncementList = () => import( '@/views/Announcements/AnnouncementList')

//EventCategory
const EventCategoryList = () => import('@/views/EventCategories/EventCategoryList')

//Venue
const VenueList = () => import( '@/views/Venues/VenueList')
const VenueProfile = () => import( '@/views/Venues/VenueProfile')

//Event
const EventList = () => import( '@/views/Events/EventList')
const EventProfile = () => import( '@/views/Events/EventProfile')

//Event TIcket
const EventTicketProfile = () => import( '@/views/EventTickets/EventTicketProfile')

//Event Section
const EventSectionProfile = () => import( '@/views/EventSections/EventSectionProfile')

//Event Door
const EventDoorProfile = () => import( '@/views/EventDoors/EventDoorProfile')

//Audit Trail
const UserTicketAuditTrailProfile = () => import( '@/views/AuditTrails/UserTicketAuditTrailProfile')

//Order
const OrderTicketList = () => import( '@/views/Orders/OrderList')
const OrderTicketProfile = () => import( '@/views/Orders/OrderProfile')


const CreditItemList = () => import( '@/views/CreditItems/CreditItemList')
const FeedbackList = () => import( '@/views/Feedbacks/FeedbackList')
const Home = () => import( '@/views/_general/Home')
const LockerBoxTypeList = () => import('@/views/LockerBoxTypes/LockerBoxTypeList')


const LockerList = () => import('@/views/Lockers/LockerList')
const LockerProfile = () => import( '@/views/Lockers/LockerProfile')

const SupportiveSingle = () => import( '@/views/Supports/SupportiveSingle')
const CustomerList = () => import( '@/views/Users/CustomerList')
const UserProfile = () => import( '@/views/Users/UserProfile')

// Product Pages
const ProductList = () => import( '@/views/Products/ProductList')
const ProductInfo = () => import( '@/views/Products/ProductInfo')

//Order
const EstoreOrderList = () => import( '@/views/EstoreOrders/OrderList')
const EstoreOrderInfo = () => import( '@/views/EstoreOrders/OrderInfo')
const EstoreOrderCheckout = () => import('@/views/EstoreOrders/OrderCheckout')

//EstoreCategory
const EstoreCategoryList = () => import('@/views/EstoreCategories/EstoreCategoryList')

//EstoreTag
const EstoreTagList = () => import('@/views/EstoreTags/EstoreTagList')

//EstoreBanner
const EstoreBannerList = () => import('@/views/EstoreBanners/EstoreBannerList')

//CREW
const CrewList = () => import( '@/views/Crews/CrewList')
const CrewProfile = () => import( '@/views/Crews/CrewProfile')

const StateList = () => import( '@/views/States/StateList')

const UserTicketProfile = () => import( '@/views/UserTickets/UserTicketProfile')

const SeatingChart = () => import( '@/views/SeatingCharts/SeatingChart')

const CourierList = () => import('@/views/Couriers/CourierList')
// const CourierInfo = () => import( '@/views/Couriers/CourierInfo')
const GeoZoneList = () => import( '@/views/GeoZones/GeoZoneList')
// const GeoZoneInfo = () => import( '@/views/GeoZones/GeoZoneInfo')
const ShippingMethodList = () => import( '@/views/ShippingMethods/ShippingMethodList')
const ShippingMethodInfo = () => import( '@/views/ShippingMethods/ShippingMethodInfo')

//Report
const ReportList = () => import('@/views/Reports/ReportList')

const EstoreFoodReportList = () => import('@/views/EstoreOrders/EstoreFoodReportList')

const OrganizerList = () => import('@/views/Organizers/OrganizerList')

const ArtistList = () => import('@/views/Artists/ArtistList')
const ArtistProfile = () => import( '@/views/Artists/ArtistProfile')

// E Voucher Pages
const EVoucherList = () => import( '@/views/EVouchers/EVoucherList')
const EVoucherInfo = () => import( '@/views/EVouchers/EVoucherInfo')

// Routes
// ** for more information, kindy refer to https://github.com/websanova/vue-auth/blob/master/docs/Privileges.md
// ** auth: true <- can be access only if auth.check = true
// ** auth: false <- cannot be access is auth.check = true
// ** auth: null, title: '' <- no auth rule
// ** auth: [1,2] <- can be access is auth.user.role matched

const baseLayoutRoutes = [
	{ path: '/comingsoon', name: 'admin.home2', component: Comingsoon, meta: { auth: [ 1,5 ], title: 'Coming Soon' } },
	{ path: '/home', name: 'home', component: Home, meta: { auth: true, title: 'Home' } },
	{ path: '/partner/home', name: 'home', component: Home, meta: { auth: true, title: 'Home' } },

	// user
	{ path: '/customer/list', name: 'customer.list', component: CustomerList, meta: { auth: true, title: 'Customer List' } },
	{ path: '/my/profile', name: 'my.profile', component: AuthProfile, meta: { auth: true, title: 'Profile' } },
	{ path: '/user/profile/:id', name: 'user.profile', component: UserProfile, meta: { auth: true, title: 'User Profile' } },

	//crew
	{ path: '/crew/list', name: 'crew.list', component: CrewList, meta: { auth: true, title: 'Crew List' } },
	{ path: '/crew/profile/:id', name: 'crew.profile', component: CrewProfile, meta: { auth: true, title: 'Crew Profile' } },

	// cedit-item
	{ path: '/credit-item/list', name: 'credit-item.list', component: CreditItemList, meta: { auth: true, title: 'Credit Items' } },

	// locker
	{ path: '/locker/list', name: 'locker.list', component: LockerList, meta: { auth: true, title: 'Locker List' } },
	{ path: '/locker/profile/:id', name: 'locker.profile', component: LockerProfile, meta: { auth: true, title: 'Locker Profile' } },

	// locker-box-type
	{ path: '/locker-box-type/list', name: 'locker-box-type.list', component: LockerBoxTypeList, meta: { auth: true, title: 'Locker Box Type List' } },
	
	// broadcast
	{ path: '/broadcast/list', name: 'broadcast.list', component: BroadcastList, meta: { auth: true, title: 'Broadcast list' } },


	// advertisement
	{ path: '/advertisement/list', name: 'advertisement.list', component: AdvertisementList, meta: { auth: true, title: 'Advertisement list' } },

	// announcement
	{ path: '/feature/list', name: 'announcement.list', component: AnnouncementList, meta: { auth: true, title: 'Feature list' } },

	// venue
	{ path: '/venue/list', name: 'venue.list', component: VenueList, meta: { auth: true, title: 'Venue list' } },
	{ path: '/venue/profile/:id', name: 'venue.profile', component: VenueProfile, meta: { auth: true, title: 'Venue profile' } },

	//event category
	{ path: '/event-category/list', name: 'event-category.list', component: EventCategoryList, meta: { auth: true, title: 'Event Category list' } },
	
	// event
	{ path: '/event/list', name: 'event.list', component: EventList, meta: { auth: true, title: 'Event list' } },
	{ path: '/event/profile/:id', name: 'event.profile', component: EventProfile, meta: { auth: true, title: 'Event profile' } },

	//event-ticket
	{ path: '/event/category/profile/:id', name: 'event.category.profile', component: EventTicketProfile, meta: { auth: true, title: 'Event Category Profile' } },

	//event-section
	{ path: '/event/section/profile/:id', name: 'event.section.profile', component: EventSectionProfile, meta: { auth: true, title: 'Event Section Profile' } },

	//event-door
	{ path: '/event/door/profile/:id', name: 'event.door.profile', component: EventDoorProfile, meta: { auth: true, title: 'Event Door Profile' } },

	//audit-trail
	{ path: '/audit-trail/user-ticket/profile/:id', name: 'audit-trail.user-ticket.profile', component: UserTicketAuditTrailProfile, meta: { auth: true, title: 'User Ticket Audit Trail Profile' } },

	// product
	{ path: '/product/list', name: 'product.list', component: ProductList, meta: { auth: true, title: 'Product List' } },
	{ path: '/product/info/:id', name: 'product.info', component: ProductInfo, meta: { auth: true, title: 'Product Info' } },

	// order
	{ path: '/estore-orders/list', name: 'estore-orders.list', component: EstoreOrderList, meta: { auth: true, title: 'Estore Order List' } },
	{ path: '/estore-orders/info/:id', name: 'estore-orders.info', component: EstoreOrderInfo, meta: { auth: true, title: 'Estore Order Info' } },	

	//estore-food-report
	{ path: '/estore-orders/estore-food-report', name: 'estore-food-report.list', component: EstoreFoodReportList, meta: { auth: true, title: 'Estore Food Report List' } },

	//estore-category
	{ path: '/estore-category/list', name: 'estore-category.list', component: EstoreCategoryList, meta: { auth: true, title: 'Estore Category list' } },

	//estore-tag
	{ path: '/estore-tag/list', name: 'estore-tag.list', component: EstoreTagList, meta: { auth: true, title: 'Estore Tag list' } },

	//estore-banner
	{ path: '/estore-banner/list', name: 'estore-banner.list', component: EstoreBannerList, meta: { auth: true, title: 'Estore Banner list' } },

	// order
	{ path: '/order/list', name: 'order.list', component: OrderTicketList, meta: { auth: true, title: 'Ticket Order list' } },

	// state
	{ path: '/state/list', name: 'state.list', component: StateList, meta: { auth: true, title: 'State List' } },

	// support
	{ path: '/support/about-us', name: 'support.about-us', component: SupportiveSingle, meta: { auth: true, title: 'About Us', params: { type: 'about-us' } } },
	{ path: '/support/refund-policy', name: 'support.refund-policy', component: SupportiveSingle, meta: { auth: true, title: 'Refund Policy', params: { type: 'refund-policy' } } },
	{ path: '/support/terms-of-use', name: 'support.terms-of-use', component: SupportiveSingle, meta: { auth: true, title: 'T&C', params: { type: 'terms-of-use' } } },
	{ path: '/support/privacy-policy', name: 'support.privacy-policy', component: SupportiveSingle, meta: { auth: true, title: 'Privacy Policy', params: { type: 'privacy-policy' } } },
	{ path: '/support/ticket-tnc', name: 'support.ticket-tnc', component: SupportiveSingle, meta: { auth: true, title: 'Ticket TnC', params: { type: 'ticket-tnc' } } },
	{ path: '/support/feedback', name: 'support.feedback', component: FeedbackList, meta: { auth: true, title: 'Feedback' } },
	
	//userTicket
	{ path: '/user-ticket/profile/:id', name: 'userTicket.profile', component: UserTicketProfile, meta: { auth: true, title: 'User Ticket Profile' } },

	// delivery module
	{ path: '/delivery/courier/list', name: 'courier.list', component: CourierList, meta: { auth: true, title: 'Courier List' } },
	{ path: '/delivery/geo-zone/list', name: 'geoZone.list', component: GeoZoneList, meta: { auth: true, title: 'Geo Zone List' } },
	{ path: '/delivery/shipping-method/list', name: 'shippingMethod.list', component: ShippingMethodList, meta: { auth: true, title: 'Shipping Method List' } },
	{ path: '/delivery/shipping-method/info', name: 'shippingMethod.info', component: ShippingMethodInfo, meta: { auth: true, title: 'Shipping Method Info' } },

	// // Report
	{ path: '/report/list', name: 'report.list', component: ReportList, meta: { auth: true, title: 'Report' } },

	{ path: '/organizer/list', name: 'organizer.list', component: OrganizerList, meta: { auth: true, title: 'Organizer List' } },

	{ path: '/artist/list', name: 'artist.list', component: ArtistList, meta: { auth: true, title: 'Artist List' } },
	{ path: '/artist/profile/:id', name: 'artist.profile', component: ArtistProfile, meta: { auth: true, title: 'Artist Profile' } },

	// e-voucher
	{ path: '/e-voucher/list', name: 'e-voucher.list', component: EVoucherList, meta: { auth: true, title: 'E Voucher List' } },
	{ path: '/e-voucher/info/:id', name: 'e-voucher.info', component: EVoucherInfo, meta: { auth: true, title: 'E Voucher Info' } },
	
]

const routes = [

	{ path: '/', name: 'login', component: Login, meta: { auth: null, title: 'Login' } },
	{ path: '/login', name: 'login', component: Login, meta: { auth: null, title: 'Login' } },
	{ path: '/password/reset/:token', name: 'reset.password', component: ResetPassword, meta: { auth: null, title: 'Reset Password' } },
	{ path: '/forgot-password', name: 'forgot.password', component: ForgotPassword, meta: { auth: null, title: 'Forgot Password' } },
	{ path: '/estore-orders/status/:status', name: 'estore-orders.checkout', component: EstoreOrderCheckout, meta: { auth: null, title: 'Order Payment Status' } },
	{ path: '/email-verify/:token', name: 'verify.email', component: VerifyEmail, meta: { auth: null, title: 'Verify Email' } },
	{ path: '/seating-chart', name: 'seating-chart', component: SeatingChart, meta: { auth: null, title: 'Seating Chart' } },

	// base_layout
	{ path: '/home', name: 'base_layout', component: BaseLayout, children: baseLayoutRoutes },

	{ path: '*', name: '404', component: Error404, meta: { auth: null, title: '404' } },
]

const router = new VueRouter({
	history: true,
	mode: 'history',
	routes,
})

router.beforeEach((to, from, next) => {
	setPageTitle(to.meta.title)
	next()
});

export default router