import axios from 'axios';

let prefix = "/estore-category"

const EstoreCategoryApi = {
	
	getEstoreCategoryList(payload){
		return window.Vue.axiosLaravel.get( prefix + "/list-pagination", {params: payload})
	},
	createEstoreCategory(payload){
		return window.Vue.axiosLaravel.post( prefix + "/create", payload)
	},
	updateEstoreCategory(payload){
		return window.Vue.axiosLaravel.put( prefix + "/update", payload)
	},
	archiveEstoreCategory(payload){
		return window.Vue.axiosLaravel.delete( prefix + "/archive", {params: payload})
	},
}

export default EstoreCategoryApi;