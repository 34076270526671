import axios from 'axios';

let prefix = "/estore-banner"

const EstoreBannerApi = {
	
	getEstoreBannerList(payload){
		return window.Vue.axiosLaravel.get( prefix + "/list-pagination", {params: payload})
	},
	updateEstoreBanner(payload){
		return window.Vue.axiosLaravel.put( prefix + "/update", payload)
	},
	getEstoreBannerRefs(payload){
		return window.Vue.axiosLaravel.get( prefix + "/refs", {params: payload})
	},
	setEstoreBannerImage(payload, onUploadProgress = {} ){
		return window.Vue.axiosLaravel.post(prefix + "/set-image", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearEstoreBannerImage(payload){
		return window.Vue.axiosLaravel.delete(prefix + "/clear-image", {params: payload})
	},
}

export default EstoreBannerApi;