import axios from 'axios';

let prefix = "/e-voucher"

const EVoucherApi = {
	
	getEVoucherInfo(id, payload){
		return window.Vue.axiosLaravel.get( prefix + "/info/"+id, {params: payload})
	},
	getEVoucherList(payload){
		return window.Vue.axiosLaravel.get( prefix + "/list", {params: payload})
	},
	createEVoucher(payload){
		return window.Vue.axiosLaravel.post( prefix + "/create", payload)
	},
	updateEVoucher(payload){
		return window.Vue.axiosLaravel.put( prefix + "/update", payload)
	},
	archiveEVoucher(payload){
		return window.Vue.axiosLaravel.delete( prefix + "/archive", {params: payload})
	},
	getUniqueCode(){
		return window.Vue.axiosLaravel.get( prefix + "/generate")
	}
}

export default EVoucherApi;