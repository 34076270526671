import axios from 'axios';

let prefix = "/estore-tag"

const EstoreTagApi = {
	
	getEstoreTagList(payload){
		return window.Vue.axiosLaravel.get( prefix + "/list-pagination", {params: payload})
	},
	createEstoreTag(payload){
		return window.Vue.axiosLaravel.post( prefix + "/create", payload)
	},
	updateEstoreTag(payload){
		return window.Vue.axiosLaravel.put( prefix + "/update", payload)
	},
	archiveEstoreTag(payload){
		return window.Vue.axiosLaravel.delete( prefix + "/archive", {params: payload})
	},
}

export default EstoreTagApi;